<template>
    <span>
        <back-to-start></back-to-start>
        <back-to-pizzas></back-to-pizzas>
        <back-to-extras></back-to-extras>
    </span>
</template>

<script>
import BackToStart from '@/components/BackToStart';
import BackToPizzas from '@/components/BackToPizzas';
import BackToExtras from '@/components/BackToExtras';

export default {
    name: 'Home',
    components: {
        BackToStart,
        BackToPizzas,
        BackToExtras,
    },
};
</script>
