<template>
    <v-container grid-list-lg fluid class="pizzas-style">
        <v-row>
            <v-flex xs12 class="text-xs-center white--text display-1 font-weight-black my-5 ml-2">Pizza:</v-flex>
        </v-row>
        <v-row justify="center" class="row-big">
            <v-col align="center" xs="6" md="3" class="card-row-big" v-for="(item, index) in pizzas" :key="index">
                <v-img :src="getPizzaImgSmall(index)" max-height="100px" max-width="150px" :alt="'pizza image'" contain></v-img>
                <h3 class="headline mb-0">{{ item.name }}</h3>
                <div style="word-break: keep-all">{{ item.description }}</div>
                <div>{{ item.price }}</div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BackToPizzas',
    data() {
        return {
            pizzas: [
                {
                    name: 'QUATRO STAGIONI',
                    img: 'Quattro Stagioni.jpg',
                    imgSmall: 'Quattro Stagioni_320.jpg',
                    description: '630g salsa di pomodoro, caș, șuncă, salam , ciuperci,  ardei gras, măsline,busuioc, oregano',
                    price: '27 lei',
                },
                {
                    name: 'CAPRICIOSA',
                    img: 'Capriciosa.jpg',
                    imgSmall: 'Capriciosa_320.jpg',
                    description: '750g salsa di pomodoro, caș, șuncă, ciuperci, ceapa, muschiulet afumat, ardei gras, măsline, busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'QUATRO FORMAGGI',
                    img: 'Quattro Formaggi.jpg',
                    imgSmall: 'Quattro Formaggi_320.jpg',
                    description: '500g sos smantana ,caș, gorgonzola, emmentaler, parmezan',
                    price: '30 lei',
                },
                {
                    name: 'PROSCIUTTO E FUNGHI',
                    img: 'Prosciutto Funghi.jpg',
                    imgSmall: 'Prosciutto Funghi_320.jpg',
                    description: '630g salsa di pomodoro, caș, șuncă, ciuperci, masline, busuioc, oregano',
                    price: '27 lei',
                },
                {
                    name: 'RUSTICA',
                    img: 'Rustica.jpg',
                    imgSmall: 'Rustica_320.jpg',
                    description: '650g salsa di pomodoro, caș , șuncă, salam, ceapă, muschiulet afumat, porumb, măsline,  busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'DIAVOLO',
                    img: 'Diavolo.jpg',
                    imgSmall: 'Diavolo_320.jpg',
                    description: '550g salsa di pomodoro, caș, salam, peperoncini, ardei iute,  busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'CHICKEN',
                    img: 'Chicken.jpg',
                    imgSmall: 'Chicken_320.jpg',
                    description: '650g salsa di pomodoro, caș, piept de pui, ciuperci, ardei gras, măsline,  busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'PROSCIUTTO CRUDO',
                    img: 'Prosciutto Crudo.jpg',
                    imgSmall: 'Prosciutto Crudo_320.jpg',
                    description: '500g salsa di pomodoro, caș, prosciutto crudo, măsline,  busuioc , oregano',
                    price: '28 lei',
                },
                {
                    name: 'VEGETARIANA',
                    img: 'Vegetariana.jpg',
                    imgSmall: 'Vegetariana_320.jpg',
                    description: '750g salsa di pomodoro, caș vegetal, ciuperci, ardei gras, ceapă, porumb, măsline,  busuioc, oregano',
                    price: '26 lei',
                },
                {
                    name: 'ROMANA',
                    img: 'Romana.jpg',
                    imgSmall: 'Romana_320.jpg',
                    description: '700g Salsa di pomodoro, caș, șuncă,ceapa, cabanos, muschiulet afumat, ou felii, roșii cherry, măsline, busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'CLAUDIO',
                    img: 'Claudio.jpg',
                    imgSmall: 'Claudio_320.jpg',
                    description: '650g sos smantana, caș, gorgonzola, emmentaler, parmezan, salam, peperoncini, ciuperci',
                    price: '35 lei',
                },
                {
                    name: 'NELUCIO',
                    img: 'Nelucio.jpg',
                    imgSmall: 'Nelucio_320.jpg',
                    description: '700g sos smântână, caș, ciuperci, bucăți de șuncă crud - afumată, rucola, roșii cherry, parmezan, măsline',
                    price: '35 lei',
                },
                {
                    name: 'SALAMI',
                    img: 'Salami.jpg',
                    imgSmall: 'Salami_320.jpg',
                    description: '530g salsa di pomodoro, caș, salam, busuioc, oregano',
                    price: '27 lei',
                },
                {
                    name: 'PAESANA',
                    img: 'Paesana.jpg',
                    imgSmall: 'Paesana_320.jpg',
                    description: '750g salsa di pomodoro, caș, salam, ciuperci, cabanos, muschiulet afumat, ardei gras, măsline,  busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'MARGHERITA',
                    img: 'Margherita.jpg',
                    imgSmall: 'Margherita_320.jpg',
                    description: '500g salsa di pomodoro, caș,  busuioc, oregano',
                    price: '22 lei',
                },
                {
                    name: 'PROSCIUTTO COTTO',
                    img: 'Prosciutto Cotto.jpg',
                    imgSmall: 'Prosciutto Cotto_320.jpg',
                    description: '500g Salsa di pomodoro ,cas, sunca, busuioc, oregano',
                    price: '26 lei',
                },
                {
                    name: 'CANIBALE',
                    img: 'Canibale.jpg',
                    imgSmall: 'Canibale_320.jpg',
                    description: '600g salsa di pomodoro, caș, șuncă, salam, cabanos, muschiulet afumat, măsline,  busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'AL TONO',
                    img: 'Al Tono.jpg',
                    imgSmall: 'Al Tono_320.jpg',
                    description: '500g salsa di pomodoro, caș, ton, măsline, ceapă,  busuioc, oregano',
                    price: '27 lei',
                },
                {
                    name: 'da VINCI',
                    img: 'DaVinci.jpg',
                    imgSmall: 'DaVinci_320.jpg',
                    description: '800g salsa di pomodoro, caș, șuncă, ciuperci, cabanos, muschiulet afumat, ou capac, ardei gras, măsline, busuioc, oregano',
                    price: '30 lei',
                },
                {
                    name: 'HAWAII',
                    img: 'Hawai.jpg',
                    imgSmall: 'Hawai_320.jpg',
                    description: '550g salsa di pomodoro, caș, șuncă, ananas,  busuioc, oregano',
                    price: '27 lei',
                },
            ],
        };
    },
    methods: {
        getPizzaImgSmall(i) {
            if (this.pizzas[i].img) {
                return require('../assets/img/pizzas/' + this.pizzas[i].imgSmall);
            }
        },
    },
};
</script>

<style scoped>
.card-row-big {
    margin: 1px;
    background-color: antiquewhite;
}
.pizzas-style {
    background: url('../assets/img/bktdvbkd.jpg');
    background-size: cover;
}
</style>
