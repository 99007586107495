<template>
    <v-container grid-list-lg fluid class="extras-style">
        <v-row justify="center">
            <v-col align="center" xs="12" md="6">
                <table cellspacing="5px" width="55%">
                    <th xs12 class="text-left white--text text-h5 font-weight-black mt-5">Sosuri:</th>
                    <tr v-for="(item, index) in sosuri" :key="index" style="vertical-align: top">
                        <td class="text-left white--text text-sm-6">{{ item.description }}</td>
                        <td class="text-left white--text" width="30px">{{ item.price }}</td>
                    </tr>
                </table>
                <table class="mt-3" cellspacing="5px" width="55%">
                    <th xs12 class="text-left white--text text-h5 font-weight-black mt-5">Extra topping:</th>
                    <tr v-for="(item, index) in extraTops" :key="index">
                        <td class="text-left white--text text-sm-6" style="margin-right: 5px">{{ item.description }}</td>
                        <td class="text-left white--text" width="30px">{{ item.price }}</td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BackToExtras',
    data() {
        return {
            sosuri: [
                {
                    description: 'Sos de maioneza cu usturoi 80g',
                    price: '4 lei',
                },
                {
                    description: 'Ketchup dulce 80g',
                    price: '3 lei',
                },
                {
                    description: 'Ketchup picant 80g',
                    price: '3 lei',
                },
                {
                    description: 'Smantana 80g',
                    price: '4 lei',
                },
            ],
            extraTops: [
                {
                    description: 'Cas       50g',
                    price: '4 lei',
                },
                {
                    description: 'Sunca     50g',
                    price: '4 lei',
                },
                {
                    description: 'Salam  50g',
                    price: '4 lei',
                },
                {
                    description: 'Muschiulet afumat   50g',
                    price: '4 lei',
                },
                {
                    description: 'Cabanos     50g',
                    price: '4 lei',
                },
                {
                    description: 'Emmentaler     50g',
                    price: '4 lei',
                },
                {
                    description: 'Gorgonzola   50g',
                    price: '4 lei',
                },
                {
                    description: 'Ciuperci    50g',
                    price: '2 lei',
                },
                {
                    description: 'Porumb     50g',
                    price: '2 lei',
                },
                {
                    description: 'Prosciuto crudo   50g',
                    price: '6 lei',
                },
                {
                    description: 'Rucola     50g',
                    price: '4 lei',
                },
            ],
        };
    },
};
</script>

<style scoped>
.extras-style {
    background: url('../assets/img/bktdvbkd.jpg');
    background-size: cover;
}
</style>
