<template>
    <v-container grid-list-lg fluid class="background-style">
        <v-layout column>
            <v-layout row nowrap>
                <div class="hidden-sm-and-down ml-3 mt-2">
                    <v-img src="../assets/img/backtdvsig.jpg" class="sig-grand" contain></v-img>
                </div>
                <div class="hidden-md-and-up ml-1 mt-2">
                    <v-img src="../assets/img/backtdvsig.jpg" class="sig-little" contain></v-img>
                </div>
                <div class="ml-9 column justify-center">
                    <div class="text-lg-h2 text-sm-h3 font-weight-black white--text text-center mb-3">Johnny &amp; Co</div>
                    <div class="text-lg-h1 text-sm-h2 font-weight-black text-center" style="color: coral">BACK TO DA VINCI</div>
                    <div class="text-lg-h2 text-sm-h3 font-weight-black text-center mb-3" style="color: coral">Style</div>
                    <div class="text-lg-h3 text-sm-h4 font-weight-bold white--text text-center mb-5">Caldă gustoasă și repede acasă!</div>
                </div>
            </v-layout>
            <div class="text-lg-h3 text-sm-h4 font-weight-bold white--text text-xs-left" style="margin: 40px 3px 5px 3px">"Vedetele:"</div>
        </v-layout>
        <v-layout row wrap class="mt-7">
            <v-row justify="center" style="heigth: 250px">
                <v-col align="center" xs="6" sm="4" md="3" class="card-row-big" v-for="(item, index) in popularItems" :key="index">
                    <v-img :src="getPizzaImgSmall(index)" max-height="100px" max-width="150px" :alt="'pizza image'" contain></v-img>
                    <h3 class="headline mb-0">{{ item.name }}</h3>
                    <div style="word-break: keep-all">{{ item.description }}</div>
                    <div>{{ item.price }}</div>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'BackToStart',
    data() {
        return {
            popularItems: [
                {
                    name: 'DIAVOLO',
                    img: 'Diavolo.jpg',
                    imgSmall: 'Diavolo_320.jpg',
                    description: '550g salsa di pomodoro, caș, salam, peperoncini, ardei iute,  busuioc, oregano',
                    price: '28 lei',
                },
                {
                    name: 'NELUCIO',
                    img: 'Nelucio.jpg',
                    imgSmall: 'Nelucio_320.jpg',
                    description: '700g sos smântână, caș, ciuperci, bucăți de șuncă crud - afumată, rucola, roșii cherry, parmezan, măsline',
                    price: '35 lei',
                },
                {
                    name: 'QUATRO FORMAGGI',
                    img: 'Quattro Formaggi.jpg',
                    imgSmall: 'Quattro Formaggi_320.jpg',
                    description: '500g sos smantana ,caș, gorgonzola, emmentaler, parmezan',
                    price: '30 lei',
                },
                {
                    name: 'CLAUDIO',
                    img: 'Claudio.jpg',
                    imgSmall: 'Claudio_320.jpg',
                    description: '650g sos smantana, caș, gorgonzola, emmentaler, parmezan, salam ,peperoncini, ciuperci',
                    price: '35 lei',
                },
            ],
        };
    },
    methods: {
        getPizzaImgSmall(i) {
            return require('../assets/img/pizzas/' + this.popularItems[i].imgSmall);
        },
    },
};
</script>

<style scoped>
.background-style {
    background: url('../assets/img/bktdvbkd.jpg');
    background-size: cover;
}
.sig-grand {
    background-color: beige;
    border: solid 1px black;
    border-radius: 50%;
    height: 250px;
    max-height: 250px;
    width: 250px;
    max-width: 250px;
}
.sig-little {
    background-color: beige;
    border: solid 1px black;
    border-radius: 50%;
    height: 100px;
    max-height: 100px;
    width: 100px;
    max-width: 100px;
}
.card-row-big {
    margin: 1px;
    background-color: antiquewhite;
}
</style>
